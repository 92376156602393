import dayjs from 'dayjs'
import _ from 'lodash'
import * as React from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { Card, CardBody, CardTitle } from 'reactstrap'

import type { BopWorkspaceData } from 'api/dashboard'

import { showError, showSuccess } from 'slices/notificationSlice'
import {
  selectTenantsStatus,
  getBopMonitoringSummary,
  getDisplayFilter,
  updateDisplayFilter,
} from 'slices/tenantsSlice'
import { selectWorkspacesStatus } from 'slices/workspacesSlice'

import {
  BadgeLabel,
  Chart,
  GroupRadioButton,
  PivotItem,
  PivotOuterIndex,
  CustomButton,
  FilteringButton,
  AmountCard,
  NotSelectedPlaceholder,
} from 'components/common'
import { createStackedChartOptions, PlaceholderTypes } from 'components/common/utils'

import useDateQuery from 'hooks/useDateQuery'

import type * as Highcharts from 'highcharts'

const BopType = {
  estimate: 'estimate',
  actual: 'actual',
}

const BopGraphGroupType = {
  sales: 'sales',
  costs: 'costs',
  salesEstimate: 'salesEstimate',
  costsEstimate: 'costsEstimate',
}

type BopGraphDataType = {
  sales: number
  otherSales: number
  costOfGoodsSold: number
  fixedCosts: number
  otherCosts: number
}

type BopGraphDataProps = {
  data: BopGraphDataType[]
  estimate?: BopGraphDataType[]
}

const toggleButtonItemList = [
  { id: BopType.estimate, label: '見込' },
  { id: BopType.actual, label: '実績' },
]

const graphList = [
  { header: '合算', key: 'total', type: 'column' },
  { header: 'ワークスペース別', key: 'workspaces', type: 'column' },
]

const BopMonitoringSummary: React.FC = () => {
  const [submitted, setSubmitted] = React.useState(false)
  const [selectedBopType, setSelectedBopType] = React.useState(BopType.estimate)
  const [graphPivotIndex, setGraphPivotIndex] = React.useState(0)
  const [selectedWorkspaces, setSelectedWorkspaces] = React.useState<number[]>([])

  const dispatch = useDispatch()
  const date = useDateQuery()

  const { bopMonitoring } = useSelector(selectTenantsStatus, shallowEqual)
  const { tenants, displayFilter, isRequesting, errorMessage } = useSelector(selectTenantsStatus, shallowEqual)
  const { workspaces } = useSelector(selectWorkspacesStatus, shallowEqual)

  React.useEffect(() => {
    dispatch(getDisplayFilter())
  }, [dispatch])

  React.useEffect(() => {
    dispatch(getBopMonitoringSummary(date, { displayFilter: true }))
  }, [dispatch, date])

  React.useEffect(() => {
    const initSelectedWorkspaces =
      displayFilter?.BOPDashboard.workspaces
        .filter(workspace => workspace.isFilteredInSummary)
        .map(workspace => workspace.id) || []
    setSelectedWorkspaces(initSelectedWorkspaces)
  }, [displayFilter, setSelectedWorkspaces])

  React.useEffect(() => {
    if (!submitted || isRequesting) {
      return
    }
    if (errorMessage === '') {
      dispatch(showSuccess())
    } else {
      dispatch(showError())
    }
    setSubmitted(false)
  }, [submitted, isRequesting, errorMessage, dispatch])

  const updatedAt = React.useMemo(() => {
    return bopMonitoring?.updatedAt && dayjs(bopMonitoring?.updatedAt).format('YYYY/MM/DD HH:mm:ss')
  }, [bopMonitoring])

  const displayData = React.useMemo(() => {
    if (!bopMonitoring) {
      return
    }

    return selectedBopType === BopType.estimate ? bopMonitoring.estimate : bopMonitoring.actual
  }, [bopMonitoring, selectedBopType])

  const estimateData = React.useMemo(() => {
    return bopMonitoring && bopMonitoring.estimate
  }, [bopMonitoring])

  const filterItems = React.useMemo(
    () =>
      workspaces?.map(workspace => ({
        key: workspace.workspaceId,
        label: workspace.name,
        checked: selectedWorkspaces.includes(workspace.workspaceId),
      })) || [],
    [workspaces, selectedWorkspaces]
  )

  const getGraphSeriesData = React.useCallback((graphData: BopGraphDataProps): Highcharts.SeriesOptionsType[] => {
    const salesData = graphData.data.map(item => item.sales)
    const otherSalesData = graphData.data.map(item => item.otherSales)
    const costOfGoodsSoldData = graphData.data.map(item => item.costOfGoodsSold)
    const fixedCostsData = graphData.data.map(item => item.fixedCosts)
    const otherCostsData = graphData.data.map(item => item.otherCosts)

    const pointPlacement = graphData.estimate ? 0.075 : undefined // pointPlacementのデフォルト値はundefined

    // custom要素にtooltip表示用のデータを追加
    const salesSeriesData = salesData.map((item, index) => ({
      y: item,
      custom: {
        type: BopGraphGroupType.sales,
        totalSales: item + otherSalesData[index],
        sales: item,
        otherSales: otherSalesData[index],
      },
    }))
    const otherSalesSeriesData = otherSalesData.map((item, index) => ({
      y: item,
      custom: {
        type: BopGraphGroupType.sales,
        totalSales: salesData[index] + item,
        sales: salesData[index],
        otherSales: item,
      },
    }))
    const costOfGoodsSoldSeriesData = costOfGoodsSoldData.map((item, index) => ({
      y: item,
      custom: {
        type: BopGraphGroupType.costs,
        totalCosts: item + fixedCostsData[index] + otherCostsData[index],
        costOfGoodsSold: item,
        fixedCosts: fixedCostsData[index],
        otherCosts: otherCostsData[index],
      },
    }))
    const fixedCostsSeriesData = fixedCostsData.map((item, index) => ({
      y: item,
      custom: {
        type: BopGraphGroupType.costs,
        totalCosts: costOfGoodsSoldData[index] + item + otherCostsData[index],
        costOfGoodsSold: costOfGoodsSoldData[index],
        fixedCosts: item,
        otherCosts: otherCostsData[index],
      },
    }))
    const otherCostsSeriesData = otherCostsData.map((item, index) => ({
      y: item,
      custom: {
        type: BopGraphGroupType.costs,
        totalCosts: costOfGoodsSoldData[index] + fixedCostsData[index] + item,
        costOfGoodsSold: costOfGoodsSoldData[index],
        fixedCosts: fixedCostsData[index],
        otherCosts: item,
      },
    }))

    const series: Highcharts.SeriesOptionsType[] = [
      {
        type: 'column',
        name: '売上',
        color: 'var(--bs-primary)',
        data: salesSeriesData,
        stack: BopGraphGroupType.sales,
        pointPlacement: pointPlacement,
        zIndex: 1,
      },
      {
        type: 'column',
        name: 'その他売上',
        color: 'var(--bs-primary-middle)',
        data: otherSalesSeriesData,
        stack: BopGraphGroupType.sales,
        pointPlacement: pointPlacement,
        zIndex: 1,
      },
      {
        type: 'column',
        name: '売上原価',
        color: 'var(--bs-danger-stronger-middle)',
        data: costOfGoodsSoldSeriesData,
        stack: BopGraphGroupType.costs,
        pointPlacement: pointPlacement,
        zIndex: 1,
      },
      {
        type: 'column',
        name: '固定費',
        color: 'var(--bs-danger-middle)',
        data: fixedCostsSeriesData,
        stack: BopGraphGroupType.costs,
        pointPlacement: pointPlacement,
        zIndex: 1,
      },
      {
        type: 'column',
        name: 'その他費用',
        color: 'var(--bs-danger-pale)',
        data: otherCostsSeriesData,
        stack: BopGraphGroupType.costs,
        pointPlacement: pointPlacement,
        zIndex: 1,
      },
    ]

    if (graphData.estimate) {
      const estimateSalesData = graphData.estimate.map(item => item.sales)
      const estimateOtherSalesData = graphData.estimate.map(item => item.otherSales)
      const estimateCostOfGoodsSoldData = graphData.estimate.map(item => item.costOfGoodsSold)
      const estimateFixedCostsData = graphData.estimate.map(item => item.fixedCosts)
      const estimateOtherCostsData = graphData.estimate.map(item => item.otherCosts)

      // 見込みのデータを挿入と追加
      series.splice(
        2,
        0,
        {
          type: 'column',
          name: '売上(見込み)',
          color: 'var(--bs-primary)',
          data: estimateSalesData,
          stack: BopGraphGroupType.salesEstimate,
          opacity: 0.5,
          pointPlacement: -pointPlacement!,
          zIndex: 0,
        },

        {
          type: 'column',
          name: 'その他売上(見込み)',
          color: 'var(--bs-primary-middle)',
          data: estimateOtherSalesData,
          stack: BopGraphGroupType.salesEstimate,
          opacity: 0.5,
          pointPlacement: -pointPlacement!,
        }
      )
      series.push(
        {
          type: 'column',
          name: '費用(見込み)',
          color: 'var(--bs-danger-stronger-middle)',
          data: estimateCostOfGoodsSoldData,
          stack: BopGraphGroupType.costsEstimate,
          opacity: 0.5,
          pointPlacement: -pointPlacement!,
          zIndex: 0,
        },
        {
          type: 'column',
          name: '固定費(見込み)',
          color: 'var(--bs-danger-middle)',
          data: estimateFixedCostsData,
          stack: BopGraphGroupType.costsEstimate,
          opacity: 0.5,
          pointPlacement: -pointPlacement!,
        },
        {
          type: 'column',
          name: 'その他費用(見込み)',
          color: 'var(--bs-danger-pale)',
          data: estimateOtherCostsData,
          stack: BopGraphGroupType.costsEstimate,
          opacity: 0.4,
          pointPlacement: -pointPlacement!,
        }
      )
    }

    return series
  }, [])

  const totalChartOptions = React.useMemo(() => {
    if (!displayData) {
      return
    }

    const graphData =
      selectedBopType === BopType.actual && estimateData
        ? {
            data: [
              {
                sales: displayData.totalWorkspaceData.sales,
                otherSales: displayData.totalWorkspaceData.otherSales,
                costOfGoodsSold: displayData.totalWorkspaceData.costOfGoodsSold,
                fixedCosts: displayData.totalWorkspaceData.fixedCosts,
                otherCosts: displayData.totalWorkspaceData.otherCosts,
              },
            ],
            estimate: [
              {
                sales: estimateData.totalWorkspaceData.sales,
                otherSales: estimateData.totalWorkspaceData.otherSales,
                costOfGoodsSold: estimateData.totalWorkspaceData.costOfGoodsSold,
                fixedCosts: estimateData.totalWorkspaceData.fixedCosts,
                otherCosts: estimateData.totalWorkspaceData.otherCosts,
              },
            ],
          }
        : {
            data: [
              {
                sales: displayData.totalWorkspaceData.sales,
                otherSales: displayData.totalWorkspaceData.otherSales,
                costOfGoodsSold: displayData.totalWorkspaceData.costOfGoodsSold,
                fixedCosts: displayData.totalWorkspaceData.fixedCosts,
                otherCosts: displayData.totalWorkspaceData.otherCosts,
              },
            ],
          }

    const optionProps = {
      seriesData: getGraphSeriesData(graphData),
      categories: [tenants[0]?.name],
      pointPadding: graphData.estimate ? -0.3 : 0.1, // pointPaddingのデフォルト値は0.1
    }

    const options = createStackedChartOptions(optionProps)

    options.tooltip!.formatter = function () {
      if (!this.point.options.custom) {
        return false
      }
      const tooltipText =
        this.point.options.custom!.type === 'sales'
          ? `
      <div style="text-align:right">売上合計：${this.point.options.custom.totalSales.toLocaleString()}円<br>
      売上：${this.point.options.custom.sales.toLocaleString()}円<br>
      その他売上：${this.point.options.custom.otherSales.toLocaleString()}円</div>`
          : `
      <div style="text-align:right">費用合計：${this.point.options.custom.totalCosts.toLocaleString()}円<br>
      売上原価：${this.point.options.custom.costOfGoodsSold.toLocaleString()}円<br>
      固定費：${this.point.options.custom.fixedCosts.toLocaleString()}円<br>
      その他費用：${this.point.options.custom.otherCosts.toLocaleString()}円</div>`

      return tooltipText
    }

    return options
  }, [displayData, estimateData, getGraphSeriesData, selectedBopType, tenants])

  const workspaceChartOptions = React.useMemo(() => {
    if (!displayData) {
      return
    }

    const sortedWorkspaces = _.sortBy(
      displayData.workspaces,
      workspace => workspace.data.sales + workspace.data.otherSales
    ).reverse()

    const sortedEstimateData = (
      sortedWorkspacesData: BopWorkspaceData[],
      estimateWorkspacesData: BopWorkspaceData[]
    ) => {
      return sortedWorkspacesData.map(workspace => estimateWorkspacesData.find(item => item.id === workspace.id))
    }

    const graphData =
      selectedBopType === BopType.actual && estimateData
        ? {
            data: sortedWorkspaces.map(workspace => {
              return {
                sales: workspace.data.sales,
                otherSales: workspace.data.otherSales,
                costOfGoodsSold:
                  workspace.data.materialCosts +
                  workspace.data.variableDirectLaborCosts +
                  workspace.data.variableIndirectLaborCosts,
                fixedCosts: workspace.data.indirectLaborCosts + workspace.data.managementCosts,
                otherCosts: workspace.data.workspaceCosts + workspace.data.tenantCostsWithWorkspace,
              }
            }),
            estimate: sortedEstimateData(sortedWorkspaces, estimateData.workspaces).map(workspace => {
              return {
                sales: workspace!.data.sales,
                otherSales: workspace!.data.otherSales,
                costOfGoodsSold:
                  workspace!.data.materialCosts +
                  workspace!.data.variableDirectLaborCosts +
                  workspace!.data.variableIndirectLaborCosts,
                fixedCosts: workspace!.data.indirectLaborCosts + workspace!.data.managementCosts,
                otherCosts: workspace!.data.workspaceCosts + workspace!.data.tenantCostsWithWorkspace,
              }
            }),
          }
        : {
            data: sortedWorkspaces.map(workspace => {
              return {
                sales: workspace.data.sales,
                otherSales: workspace.data.otherSales,
                costOfGoodsSold:
                  workspace.data.materialCosts +
                  workspace.data.variableDirectLaborCosts +
                  workspace.data.variableIndirectLaborCosts,
                fixedCosts: workspace.data.indirectLaborCosts + workspace.data.managementCosts,
                otherCosts: workspace.data.workspaceCosts + workspace.data.tenantCostsWithWorkspace,
              }
            }),
          }

    const optionProps = {
      seriesData: getGraphSeriesData(graphData),
      categories: sortedWorkspaces.map(workspace => workspace.name),
      pointPadding: graphData.estimate ? -0.3 : 0.1, // pointPaddingのデフォルト値は0.1
    }

    const options = createStackedChartOptions(optionProps)

    options.tooltip!.formatter = function () {
      if (!this.point.options.custom) {
        return false
      }
      const tooltipText =
        this.point.options.custom!.type === 'sales'
          ? `
      <div style="text-align:right">売上合計：${this.point.options.custom.totalSales.toLocaleString()}円<br>
      売上：${this.point.options.custom.sales.toLocaleString()}円<br>
      その他売上：${this.point.options.custom.otherSales.toLocaleString()}円</div>`
          : `
      <div style="text-align:right">費用合計：${this.point.options.custom.totalCosts.toLocaleString()}円<br>
      売上原価：${this.point.options.custom.costOfGoodsSold.toLocaleString()}円<br>
      固定費：${this.point.options.custom.fixedCosts.toLocaleString()}円<br>
      その他費用：${this.point.options.custom.otherCosts.toLocaleString()}円</div>`

      return tooltipText
    }

    return options
  }, [displayData, estimateData, getGraphSeriesData, selectedBopType])

  const onChangeToggle = React.useCallback((id: string) => {
    setSelectedBopType(id)
  }, [])

  const onSelectedWorkspaceFilter = React.useCallback(
    (items: number[]) => {
      if (_.isEqual(items, selectedWorkspaces)) {
        return
      }

      setSelectedWorkspaces(items)
      dispatch(getBopMonitoringSummary(date, { getWorkspaces: items.join() }))
    },
    [date, dispatch, selectedWorkspaces]
  )

  const onClickWorkspaceFilterSaveButton = React.useCallback(() => {
    if (!displayFilter) {
      return
    }

    setSubmitted(true)
    const updateWorkspaces = displayFilter.BOPDashboard.workspaces.map(df => {
      return { ...df, isFilteredInSummary: selectedWorkspaces.includes(df.id) }
    })

    dispatch(updateDisplayFilter({ BOPDashboard: { workspaces: updateWorkspaces } }))
  }, [dispatch, displayFilter, selectedWorkspaces])

  const chartOptions = React.useMemo(
    () => (graphList[graphPivotIndex].key === 'total' ? totalChartOptions : workspaceChartOptions),
    [graphPivotIndex, totalChartOptions, workspaceChartOptions]
  )

  return (
    <Card className="mt-2">
      <CardBody className="p-4">
        <div className="d-flex align-items-baseline">
          <CardTitle className="mb-3 fw-bold font-large text-nowrap">収支</CardTitle>
          <div className="w-100 d-flex justify-content-end">
            <div className="me-2">
              <FilteringButton
                items={filterItems}
                value={selectedWorkspaces}
                onChange={items => onSelectedWorkspaceFilter(items)}
                label="表示ワークスペースの選択"
                size="sm"
              ></FilteringButton>
            </div>
            <CustomButton outline icon="save" size="sm" onClick={() => onClickWorkspaceFilterSaveButton()}>
              表示ワークスペースの保存
            </CustomButton>
          </div>
        </div>

        {_.isEmpty(selectedWorkspaces) ? (
          <NotSelectedPlaceholder type={PlaceholderTypes.workspace} />
        ) : (
          <>
            <GroupRadioButton items={toggleButtonItemList} onChange={onChangeToggle} />
            <div className="d-flex gap-2 mt-2">
              <AmountCard
                amount={displayData?.totalWorkspaceData.totalSales.toLocaleString() || '0'}
                badgeLabel="売上"
                badgeColor="primary"
                className="h-100 w-100"
              />
              <AmountCard
                amount={displayData?.totalWorkspaceData.totalCosts.toLocaleString() || '0'}
                badgeLabel="費用"
                badgeColor="danger-stronger-middle"
                className="h-100 w-100"
              />
              <AmountCard
                amount={displayData?.totalWorkspaceData.profit.toLocaleString() || '0'}
                ratio={displayData?.totalWorkspaceData.profitRatio.toString() || '0'}
                badgeLabel="利益"
                badgeColor="success"
                className="h-100 w-100"
              />
            </div>

            <PivotOuterIndex selectedIndex={graphPivotIndex} onChange={setGraphPivotIndex}>
              {graphList.map(({ header, key }) => (
                <PivotItem headerText={header} key={key} />
              ))}
            </PivotOuterIndex>
            <Chart options={chartOptions!} />

            <CardBody className="d-flex p-2">
              <BadgeLabel label="売上" color="primary" />
              <BadgeLabel label="その他売上" color="primary-middle" />
              <BadgeLabel label="売上原価" color="danger-stronger-middle" />
              <BadgeLabel label="固定費" color="danger-middle" />
              <BadgeLabel label="その他費用" color="danger-pale" />
            </CardBody>

            <CardBody className="d-flex text-muted justify-content-end  pt-0 pb-0">
              {updatedAt && <i className="icf-updated align-self-center pe-1" />}
              <small>{updatedAt}</small>
            </CardBody>
          </>
        )}
      </CardBody>
    </Card>
  )
}

export default BopMonitoringSummary
