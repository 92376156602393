import dayjs from 'dayjs'
import _ from 'lodash'
import * as React from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { Card, CardBody, CardTitle, Input, Label } from 'reactstrap'

import type { BopDateData } from 'api/bop_reports'

import { getBopReports, selectBopReportsStatus } from 'slices/bopReportsSlice'
import { showError, showSuccess } from 'slices/notificationSlice'
import { selectTenantsStatus, getDisplayFilter, updateDisplayFilter } from 'slices/tenantsSlice'
import { selectWorkspacesStatus } from 'slices/workspacesSlice'

import {
  BadgeLabel,
  Chart,
  GroupRadioButton,
  CustomButton,
  FilteringButton,
  AmountCard,
  NotSelectedPlaceholder,
} from 'components/common'
import { createStackedChartOptions, PlaceholderTypes } from 'components/common/utils'

import useBopReportsQuery from 'hooks/useBopReportsQuery'

import BopReportsCommon from './BopReportsCommon'

import type { XAxisOptions } from 'highcharts'

type BopGraphDataProps = {
  data: BopDateData[]
}

const BopType = {
  estimate: 'estimate',
  actual: 'actual',
}

const toggleButtonItemList = [
  { id: BopType.estimate, label: '見込' },
  { id: BopType.actual, label: '実績' },
]

const BopGraphGroupType = {
  sales: 'sales',
  costs: 'costs',
}

const BopReportsBop: React.FC = () => {
  const [submitted, setSubmitted] = React.useState(false)
  const [selectedBopType, setSelectedBopType] = React.useState(BopType.actual)
  const [selectedWorkspaces, setSelectedWorkspaces] = React.useState<number[]>([])
  const [isCheckedEstimate, setIsCheckedEstimate] = React.useState(false)
  const [isCheckedAverage, setIsCheckedAverage] = React.useState(false)

  const { queryStart, queryEnd } = useBopReportsQuery()
  const dispatch = useDispatch()

  const { displayFilter, isRequesting, errorMessage } = useSelector(selectTenantsStatus, shallowEqual)
  const { workspaces } = useSelector(selectWorkspacesStatus, shallowEqual)
  const { bopReportsBop } = useSelector(selectBopReportsStatus, shallowEqual)

  const workspaceIds = React.useMemo(() => workspaces.map(w => w.workspaceId).slice(0, 5), [workspaces])

  React.useEffect(() => {
    dispatch(getDisplayFilter())
  }, [dispatch])

  React.useEffect(() => {
    dispatch(getBopReports({ from: queryStart, to: queryEnd, displayFilter: true }))
  }, [dispatch, queryStart, queryEnd])

  React.useEffect(() => {
    const initSelectedWorkspaces =
      displayFilter?.BOPReport.workspaces
        .filter(workspace => workspace.isFilteredInSummary)
        .map(workspace => workspace.id) || []
    setSelectedWorkspaces(initSelectedWorkspaces)
  }, [displayFilter, setSelectedWorkspaces])

  React.useEffect(() => {
    if (!submitted || isRequesting) {
      return
    }
    if (errorMessage === '') {
      dispatch(showSuccess())
    } else {
      dispatch(showError())
    }
    setSubmitted(false)
  }, [submitted, isRequesting, errorMessage, dispatch])

  const updatedAt = React.useMemo(() => {
    return bopReportsBop?.updatedAt && dayjs(bopReportsBop?.updatedAt).format('YYYY/MM/DD HH:mm:ss')
  }, [bopReportsBop])

  const isTodayAfter = React.useCallback((date: string) => {
    return !dayjs(date).startOf('day').isBefore(dayjs().startOf('day'), 'days')
  }, [])

  const isActualType = React.useMemo(() => {
    return selectedBopType === BopType.actual
  }, [selectedBopType])

  const periodDays = React.useMemo(() => {
    const period = dayjs(queryEnd).diff(queryStart, 'days') + 1
    if (!isActualType || (isActualType && isCheckedEstimate) || !isTodayAfter(queryEnd)) {
      return period
    }

    // 今日以降の日数
    const afterTodayPeriod = dayjs(queryEnd).startOf('day').diff(dayjs().startOf('day'), 'days') + 1

    // 期間から今日以降の日数を引いて、実績の日数を計算
    return period - afterTodayPeriod
  }, [queryEnd, queryStart, isActualType, isCheckedEstimate, isTodayAfter])

  const averageOfDays = React.useCallback(
    (value: number) => {
      return periodDays > 0 ? Number(_.floor(value / periodDays, 0).toFixed(0)) : value
    },
    [periodDays]
  )

  const displayData = React.useMemo(() => {
    if (!bopReportsBop) {
      return
    }

    if (!isActualType) {
      return isCheckedAverage
        ? {
            totalPeriodData: {
              totalSales: averageOfDays(bopReportsBop.estimate.totalPeriodData.totalSales),
              sales: averageOfDays(bopReportsBop.estimate.totalPeriodData.sales),
              otherSales: averageOfDays(bopReportsBop.estimate.totalPeriodData.otherSales),
              totalCosts: averageOfDays(bopReportsBop.estimate.totalPeriodData.totalCosts),
              costOfGoodsSold: averageOfDays(bopReportsBop.estimate.totalPeriodData.costOfGoodsSold),
              fixedCosts: averageOfDays(bopReportsBop.estimate.totalPeriodData.fixedCosts),
              otherCosts: averageOfDays(bopReportsBop.estimate.totalPeriodData.otherCosts),
              profit: averageOfDays(bopReportsBop.estimate.totalPeriodData.profit),
              profitRatio: bopReportsBop.estimate.totalPeriodData.profitRatio,
            },
            data: bopReportsBop.estimate.data,
          }
        : bopReportsBop.estimate
    }

    const getIncludesEstimateData = () => {
      // 見込のデータを含める場合
      const estimateDateData = bopReportsBop.estimate.data.filter(item => isTodayAfter(item.date))

      const estimateSales = _.sumBy(estimateDateData, 'sales')
      const estimateOtherSales = _.sumBy(estimateDateData, 'otherSales')
      const estimateCostOfGoodsSold = _.sumBy(estimateDateData, 'costOfGoodsSold')
      const estimateFixedCosts = _.sumBy(estimateDateData, 'fixedCosts')
      const estimateOtherCosts = _.sumBy(estimateDateData, 'otherCosts')
      const estimateTotalSales = estimateSales + estimateOtherSales
      const estimateTotalCosts = estimateCostOfGoodsSold + estimateFixedCosts + estimateOtherCosts
      const estimateProfit = estimateTotalSales - estimateTotalCosts
      const estimateProfitRatio =
        ((bopReportsBop.actual.totalPeriodData.profit + estimateProfit) /
          (bopReportsBop.actual.totalPeriodData.totalSales + estimateTotalSales)) *
        100

      const includeEstimateData = {
        totalPeriodData: {
          totalSales: bopReportsBop.actual.totalPeriodData.totalSales + estimateTotalSales,
          sales: bopReportsBop.actual.totalPeriodData.sales + estimateSales,
          otherSales: bopReportsBop.actual.totalPeriodData.otherSales + estimateOtherSales,
          totalCosts: bopReportsBop.actual.totalPeriodData.totalCosts + estimateTotalCosts,
          costOfGoodsSold: bopReportsBop.actual.totalPeriodData.costOfGoodsSold + estimateCostOfGoodsSold,
          fixedCosts: bopReportsBop.actual.totalPeriodData.fixedCosts + estimateFixedCosts,
          otherCosts: bopReportsBop.actual.totalPeriodData.otherCosts + estimateOtherCosts,
          profit: bopReportsBop.actual.totalPeriodData.profit + estimateProfit,
          profitRatio: estimateProfitRatio,
        },
        data: bopReportsBop.actual.data.concat(estimateDateData),
      }

      return includeEstimateData
    }

    const emptyData = bopReportsBop.estimate.data
      .filter(item => isTodayAfter(item.date))
      .map(item => ({
        date: item.date,
        sales: 0,
        otherSales: 0,
        costOfGoodsSold: 0,
        fixedCosts: 0,
        otherCosts: 0,
        profitRatio: 0,
      }))

    const actualData = isCheckedEstimate && isTodayAfter(queryEnd) ? getIncludesEstimateData() : bopReportsBop.actual

    return isCheckedAverage
      ? {
          totalPeriodData: {
            totalSales: averageOfDays(actualData.totalPeriodData.totalSales),
            sales: averageOfDays(actualData.totalPeriodData.sales),
            otherSales: averageOfDays(actualData.totalPeriodData.otherSales),
            totalCosts: averageOfDays(actualData.totalPeriodData.totalCosts),
            costOfGoodsSold: averageOfDays(actualData.totalPeriodData.costOfGoodsSold),
            fixedCosts: averageOfDays(actualData.totalPeriodData.fixedCosts),
            otherCosts: averageOfDays(actualData.totalPeriodData.otherCosts),
            profit: averageOfDays(actualData.totalPeriodData.profit),
            profitRatio: actualData.totalPeriodData.profitRatio,
          },
          data: isCheckedEstimate ? actualData.data : actualData.data.concat(emptyData),
        }
      : {
          totalPeriodData: actualData.totalPeriodData,
          data: isCheckedEstimate ? actualData.data : actualData.data.concat(emptyData),
        }
  }, [bopReportsBop, isActualType, isCheckedEstimate, isTodayAfter, queryEnd, isCheckedAverage, averageOfDays])

  const getGraphSeriesData = React.useCallback(
    (graphData: BopGraphDataProps): Highcharts.SeriesOptionsType[] => {
      const salesData = graphData.data.map(item => item.sales)
      const otherSalesData = graphData.data.map(item => item.otherSales)
      const costOfGoodsSoldData = graphData.data.map(item => item.costOfGoodsSold)
      const fixedCostsData = graphData.data.map(item => item.fixedCosts)
      const otherCostsData = graphData.data.map(item => item.otherCosts)
      const profitRatioData = graphData.data.map(item => item.profitRatio)
      const dateData = graphData.data.map(item => item.date)

      const pointPlacement = 0.09

      // 実績表示時に見込み（本日以降）のデータはopacityを設定する
      const salesSeriesData = salesData.map((item, index) => ({
        y: item,
        color: 'var(--bs-primary)',
        className: isActualType && isTodayAfter(dateData[index]) ? 'opacity-50' : '',
      }))
      const otherSalesSeriesData = otherSalesData.map((item, index) => ({
        y: item,
        color: 'var(--bs-primary-middle)',
        className: isActualType && isTodayAfter(dateData[index]) ? 'opacity-50' : '',
      }))
      const costOfGoodsSoldSeriesData = costOfGoodsSoldData.map((item, index) => ({
        y: item,
        color: 'var(--bs-danger-stronger-middle)',
        className: isActualType && isTodayAfter(dateData[index]) ? 'opacity-50' : '',
      }))
      const fixedCostsSeriesData = fixedCostsData.map((item, index) => ({
        y: item,
        color: 'var(--bs-danger-middle)',
        className: isActualType && isTodayAfter(dateData[index]) ? 'opacity-50' : '',
      }))
      const otherCostsSeriesData = otherCostsData.map((item, index) => ({
        y: item,
        color: 'var(--bs-danger-pale)',
        className: isActualType && isTodayAfter(dateData[index]) ? 'opacity-50' : '',
      }))
      const profitRatioSeriesData = profitRatioData.map((item, index) => {
        // 実績表示かつ本日以降のデータはnullにする
        if (isActualType && isTodayAfter(dateData[index])) {
          return null
        }

        return {
          y: item,
          color: 'var(--bs-success)',
        }
      })

      const series: Highcharts.SeriesOptionsType[] = [
        {
          type: 'column',
          name: '売上',
          data: salesSeriesData,
          stack: BopGraphGroupType.sales,
          pointPlacement: pointPlacement,
          zIndex: 0,
        },
        {
          type: 'column',
          name: 'その他売上',
          data: otherSalesSeriesData,
          stack: BopGraphGroupType.sales,
          pointPlacement: pointPlacement,
          zIndex: 0,
        },
        {
          type: 'column',
          name: '売上原価',
          data: costOfGoodsSoldSeriesData,
          stack: BopGraphGroupType.costs,
          pointPlacement: -pointPlacement,
          zIndex: 0,
        },
        {
          type: 'column',
          name: '固定費',
          data: fixedCostsSeriesData,
          stack: BopGraphGroupType.costs,
          pointPlacement: -pointPlacement,
          zIndex: 0,
        },
        {
          type: 'column',
          name: 'その他費用',
          data: otherCostsSeriesData,
          stack: BopGraphGroupType.costs,
          pointPlacement: -pointPlacement,
          zIndex: 0,
        },
        {
          type: 'line',
          name: '利益率',
          color: 'var(--bs-success)',
          data: profitRatioSeriesData,
          yAxis: 1,
          zIndex: 1,
          dashStyle: 'Dash',
        },
      ]

      // 実績かつ見込を含む表示
      if (isActualType && isCheckedEstimate) {
        // 見込の利益率のグラフのデータを作成し、グラフに追加する
        // 前日から期間最後までのデータを作成（前日以前はnullになる）
        const estimateProfitRatioSeriesData = profitRatioData.map((item, index) => {
          if (dayjs(dateData[index]).startOf('day').isBefore(dayjs().startOf('day').subtract(1, 'days'), 'days')) {
            return null
          }

          return {
            y: item,
            color: 'rgba(var(--bs-success-rgb), 0.5)',
          }
        })

        series.push({
          type: 'line',
          name: '利益率(見込)',
          color: 'rgba(var(--bs-success-rgb), 0.5)',
          data: estimateProfitRatioSeriesData,
          yAxis: 1,
          zIndex: 0,
          dashStyle: 'Dash',
        })
      }

      return series
    },
    [isActualType, isCheckedEstimate, isTodayAfter]
  )

  const chartOptions = React.useMemo(() => {
    if (!displayData) {
      return
    }

    const graphData = {
      data: displayData.data,
    }
    const categories = graphData.data.map(item => item.date)
    const maxY = _.maxBy(graphData.data, 'profitRatio')?.profitRatio
    const optionProps = {
      seriesData: getGraphSeriesData(graphData),
      categories: categories,
      pointPadding: 0.3, // pointPaddingのデフォルト値は0.1
    }
    const options = createStackedChartOptions(optionProps)
    ;(options.xAxis as XAxisOptions).labels!.formatter = function () {
      return dayjs(this.value).format('MM/DD')
    }
    options.yAxis = _.concat(options.yAxis!, [
      {
        title: {
          text: undefined,
        },
        max: maxY || 100,
        min: 0,
        opposite: true,
      },
    ])
    options.tooltip!.shared = true
    options.tooltip!.formatter = function () {
      // this.points[5]に利益率のデータが入ってくる
      if (!this.points || !this.points[5]) {
        // this.points[5]がない場合はツールチップは非表示にする
        // 実績表示で見込を含まない場合に見込部分(本日以降)のデータでthis.points[5]は存在しない
        return false
      }

      const date = dayjs(this.points[0].x).format('YYYY/MM/DD')
      const totalSales = this.points[0].y! + this.points[1].y!
      const totalCosts = this.points[2].y! + this.points[3].y! + this.points[4].y!
      const profitRatio = this.points[5].y!

      const tooltipText = `
      <div style="text-align:right">${date}<br>
      売上：${totalSales.toLocaleString()}円<br>
      費用：${totalCosts.toLocaleString()}円<br>
      利益率：${profitRatio}%
      </div>`

      return tooltipText
    }

    return options
  }, [displayData, getGraphSeriesData])

  const filterItems = React.useMemo(
    () =>
      workspaces?.map(workspace => ({
        key: workspace.workspaceId,
        label: workspace.name,
        checked: selectedWorkspaces.includes(workspace.workspaceId),
      })) || [],
    [workspaces, selectedWorkspaces]
  )

  const onChangeToggle = React.useCallback((id: string) => {
    setSelectedBopType(id)
  }, [])

  const onSelectedWorkspaceFilter = React.useCallback(
    (items: number[]) => {
      if (_.isEqual(items, selectedWorkspaces)) {
        return
      }

      setSelectedWorkspaces(items)
      dispatch(getBopReports({ from: queryStart, to: queryEnd, getWorkspaces: items.join() }))
    },
    [dispatch, queryEnd, queryStart, selectedWorkspaces]
  )

  const onClickWorkspaceFilterSaveButton = React.useCallback(() => {
    if (!displayFilter) {
      return
    }

    setSubmitted(true)
    const updateWorkspaces = displayFilter.BOPReport.workspaces.map(df => {
      return { ...df, isFilteredInSummary: selectedWorkspaces.includes(df.id) }
    })

    dispatch(updateDisplayFilter({ BOPReport: { workspaces: updateWorkspaces } }))
  }, [dispatch, displayFilter, selectedWorkspaces])

  return (
    <BopReportsCommon selectedWorkspaceIds={workspaceIds}>
      <Card className="mt-2">
        <CardBody className="p-4">
          <div className="d-flex align-items-baseline">
            <CardTitle className="fw-bold font-large text-nowrap">収支</CardTitle>
            <div className="w-100 d-flex justify-content-end">
              <div className="me-2">
                <FilteringButton
                  items={filterItems}
                  value={selectedWorkspaces}
                  onChange={items => onSelectedWorkspaceFilter(items)}
                  label="表示ワークスペースの選択"
                  size="sm"
                ></FilteringButton>
              </div>
              <CustomButton outline icon="save" size="sm" onClick={() => onClickWorkspaceFilterSaveButton()}>
                表示ワークスペースの保存
              </CustomButton>
            </div>
          </div>

          {_.isEmpty(selectedWorkspaces) ? (
            <NotSelectedPlaceholder type={PlaceholderTypes.workspace} />
          ) : (
            <>
              <div className="d-flex align-items-baseline mt-2">
                <GroupRadioButton
                  items={toggleButtonItemList}
                  initSelectedId={toggleButtonItemList[1].id}
                  onChange={onChangeToggle}
                />
                {isActualType && (
                  <>
                    <div className="form-check ms-3">
                      <Input
                        className="form-check-input"
                        id="expectation"
                        checked={isCheckedEstimate}
                        type="checkbox"
                        onChange={e => setIsCheckedEstimate(e.target.checked)}
                      />
                      <Label className="form-check-label mb-0" for="expectation">
                        見込を含んだ予測表示
                      </Label>
                    </div>
                  </>
                )}
              </div>
              <div className="d-flex mt-2 gap-2">
                <AmountCard
                  amount={displayData?.totalPeriodData.totalSales.toLocaleString() || '0'}
                  badgeLabel={isCheckedAverage ? '平均売上' : '売上'}
                  badgeColor="primary"
                  className="h-100 w-100"
                />
                <AmountCard
                  amount={displayData?.totalPeriodData.totalCosts.toLocaleString() || '0'}
                  badgeLabel={isCheckedAverage ? '平均費用' : '費用'}
                  badgeColor="danger-stronger-middle"
                  className="h-100 w-100"
                />
                <AmountCard
                  amount={displayData?.totalPeriodData.profit.toLocaleString() || '0'}
                  ratio={displayData?.totalPeriodData.profitRatio.toString() || '0'}
                  badgeLabel={isCheckedAverage ? '平均利益' : '利益'}
                  badgeColor="success"
                  className="h-100 w-100"
                />
              </div>
              <div className="mt-2 d-flex gap-2">
                <AmountCard
                  amount={displayData?.totalPeriodData.costOfGoodsSold.toLocaleString() || '0'}
                  badgeLabel={isCheckedAverage ? '平均売上原価' : '売上原価'}
                  badgeColor="danger-stronger-middle"
                  className="h-100"
                />
                <AmountCard
                  amount={displayData?.totalPeriodData.fixedCosts.toLocaleString() || '0'}
                  badgeLabel={isCheckedAverage ? '平均固定費' : '固定費'}
                  badgeColor="danger-middle"
                  className="h-100"
                />
                <AmountCard
                  amount={displayData?.totalPeriodData.otherCosts.toLocaleString() || '0'}
                  badgeLabel={isCheckedAverage ? '平均その他費用' : 'その他費用'}
                  badgeColor="danger-pale"
                  className="h-100"
                />
                <div className="form-check ms-3 align-self-center">
                  <Input
                    className="form-check-input"
                    id="average"
                    checked={isCheckedAverage}
                    type="checkbox"
                    onChange={e => setIsCheckedAverage(e.target.checked)}
                  />
                  <Label className="form-check-label" for="average">
                    平均表示
                  </Label>
                </div>
              </div>

              <div className="mt-4">
                <Chart options={chartOptions!} />
              </div>

              <CardBody className="d-flex p-2">
                <BadgeLabel label="売上" color="primary" />
                <BadgeLabel label="その他売上" color="primary-middle" />
                <BadgeLabel label="売上原価" color="danger-stronger-middle" />
                <BadgeLabel label="固定費" color="danger-middle" />
                <BadgeLabel label="その他費用" color="danger-pale" />
                <BadgeLabel label="利益率" color="success" isFill={false} isDashBorder={true} />
              </CardBody>

              <CardBody className="d-flex text-muted justify-content-end pt-0 pb-0">
                {updatedAt && <i className="icf-updated align-self-center pe-1" />}
                <small>{updatedAt}</small>
              </CardBody>
            </>
          )}
        </CardBody>
      </Card>
    </BopReportsCommon>
  )
}

export default BopReportsBop
