import _ from 'lodash'
import moment from 'moment/moment'
import * as React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Row, Button, Col, Card, CardBody, CardTitle } from 'reactstrap'

import { ENABLE_DIALOG_ERROR_STATUS_CODES } from 'api/utils'
import type { EditTemplateProps } from 'api/workspaces'

import { showError, showSuccess } from 'slices/notificationSlice'
import { getScheduleTypeList, selectScheduleTypesStatus } from 'slices/scheduleTypesSlice'
import { selectSessionStatus } from 'slices/sessionSlice'
import { getTenant } from 'slices/tenantsSlice'
import {
  selectWorkspacesStatus,
  clearErrorMessage,
  updateTemplateData,
  getTemplateData,
  getTemplateList,
  getWorkspace,
} from 'slices/workspacesSlice'

import WorkPlanPopover from 'components/Schedules/WorkPlan/WorkPlanPopover'
import {
  NavMenu,
  List,
  InputFormat,
  CustomButton,
  ShiftBar,
  TimeScale,
  CardSubmitFooter,
  BadgeLabel,
} from 'components/common'
import type { ListItem } from 'components/common/types'
import { UNSELECTED_SCHEDULE_TYPE_ID, ColumnSizes } from 'components/common/utils'

import useBusinessTime from 'hooks/useBusinessTime'

import placeholderImage from 'images/allEmpty.svg'

import TemplateDelete from './TemplateDelete'

import styles from './TemplateEdit.module.scss'

const initEditTemplateData = {
  id: null,
  schedules: Array(96).fill(null),
  name: '',
}
// templateIDが1から始まるため､新規追加アイテムのIDは0とする
const NEW_TEMPLATE_ID = 0

const onDetailClick = () => window.open('https://help.smileboard.jp/use_templates', '_blank')

const TemplateEdit: React.FC = () => {
  const params = useParams<'workspaceId'>()
  const workspaceId = Number(params.workspaceId)
  const [templateNameValidity, setTemplateNameValidity] = React.useState(false)
  const [editTemplateData, setEditTemplateData] = React.useState<EditTemplateProps>(initEditTemplateData)
  const [selectedTemplateId, setSelectedTemplateId] = React.useState<number>()
  const [openDelete, setOpenDelete] = React.useState(false)
  const [listItems, setListItems] = React.useState<ListItem[]>([])
  const [submitted, setSubmitted] = React.useState(false)

  const dispatch = useDispatch()

  const { user } = useSelector(selectSessionStatus, shallowEqual)
  const { scheduleTypes } = useSelector(selectScheduleTypesStatus, shallowEqual)
  const { workspaces, templateList, templateData, isRequesting, errorMessage } = useSelector(
    selectWorkspacesStatus,
    shallowEqual
  )

  const tenantId = React.useMemo(() => user.tenants[0]?.tenantId, [user])

  React.useEffect(() => {
    if (!tenantId) {
      return
    }
    dispatch(getTenant(tenantId))
  }, [dispatch, tenantId])

  const { businessStartTime, businessDuration, getShiftBarXbyStartTime } = useBusinessTime()

  React.useEffect(() => {
    dispatch(getTemplateList(workspaceId))
    dispatch(getScheduleTypeList(workspaceId))
    dispatch(getWorkspace(workspaceId))
  }, [dispatch, workspaceId])

  React.useEffect(() => {
    if (selectedTemplateId !== NEW_TEMPLATE_ID && selectedTemplateId) {
      dispatch(getTemplateData(workspaceId, selectedTemplateId))
    }
  }, [dispatch, workspaceId, selectedTemplateId])

  React.useEffect(() => {
    if (!templateData) {
      return
    }
    setEditTemplateData({
      id: templateData.id,
      schedules: templateData.schedules,
      name: templateData.name,
    })
  }, [templateData])

  React.useEffect(() => {
    setListItems(templateList?.templates.map(({ id, name }) => ({ id, title: name })) || [])
  }, [templateList])

  React.useEffect(() => {
    if (_.isEmpty(listItems)) {
      return
    }

    setSelectedTemplateId(prev => {
      // 追加アイテムを保存した時
      if (prev === NEW_TEMPLATE_ID && !listItems.some(item => item.id === NEW_TEMPLATE_ID)) {
        return Number(_.last(listItems)!.id)
      }
      // 初期化時とアイテム削除時
      if (prev === undefined || !listItems.some(item => item.id === prev)) {
        return Number(listItems[0].id)
      }
      return prev
    })
  }, [listItems, selectedTemplateId])

  const hasItems = React.useMemo(() => !_.isEmpty(listItems), [listItems])

  const templateSchedules = React.useMemo(() => {
    return (
      editTemplateData.schedules
        ?.reduce<{ count: number; scheduleTypeId: number | null; startIndex: number }[]>((acc, cur, index) => {
          const prev = _.last(acc)
          if (!prev || prev.scheduleTypeId !== cur) {
            return acc.concat({ scheduleTypeId: cur, count: 1, startIndex: index })
          }
          acc.splice(acc.length - 1, 1, { ...prev, count: prev.count + 1 })
          return acc
        }, [])
        .filter(d => d.scheduleTypeId !== null) || []
    )
  }, [editTemplateData])

  // 始業時間が､96の配列の内の何番目かを示す数値
  const businessStartIndex = React.useMemo(() => {
    const momentBusinessStartTime = moment(businessStartTime, 'HH:mm')
    return (momentBusinessStartTime.unix() - momentBusinessStartTime.startOf('days').unix()) / 900
  }, [businessStartTime])

  const disabled = React.useMemo(
    () => !(editTemplateData && templateNameValidity),
    [editTemplateData, templateNameValidity]
  )

  const unchanged = React.useMemo(
    () =>
      editTemplateData.name === templateData?.name && _.isEqual(editTemplateData.schedules, templateData?.schedules),
    [templateData, editTemplateData]
  )

  const handleSubmit = React.useCallback(() => {
    if (!editTemplateData) {
      return
    }
    const id = editTemplateData.id === NEW_TEMPLATE_ID ? null : editTemplateData.id
    setSubmitted(true)
    dispatch(
      updateTemplateData(workspaceId, {
        ...editTemplateData,
        id,
      })
    )
  }, [dispatch, editTemplateData, workspaceId])

  React.useEffect(() => {
    if (!submitted || isRequesting) {
      return
    }

    if (errorMessage === '') {
      dispatch(showSuccess())
    } else {
      // ENABLE_DIALOG_ERROR_STATUS_CODESのときにはエラーダイアログが出るのでNotificationは出さない
      if (!ENABLE_DIALOG_ERROR_STATUS_CODES.includes(errorMessage)) {
        dispatch(showError())
      }
      dispatch(clearErrorMessage())
    }
    dispatch(getTemplateList(workspaceId))
    setSubmitted(false)
  }, [submitted, isRequesting, errorMessage, dispatch, workspaceId])

  const templateNameValidation = React.useCallback((value: string | undefined): string => {
    if (typeof value === 'undefined') {
      return ''
    }
    if (value.length === 0 || value.length > 20) {
      return '1文字以上､20文字以下で入力してください'
    }
    return ''
  }, [])

  const updated = React.useMemo(
    () => (selectedTemplateId === NEW_TEMPLATE_ID ? undefined : templateData),
    [templateData, selectedTemplateId]
  )

  const workspace = React.useMemo(() => workspaces.find(w => w.workspaceId === workspaceId), [workspaces, workspaceId])

  const badgeLabel = React.useMemo(() => {
    const item = workspaces.find(w => w.workspaceId === workspaceId)
    return item?.name || ''
  }, [workspaces, workspaceId])

  const updateEditTemplate = React.useCallback(
    (scheduleTypeId: number | null, start: number, end: number) =>
      setEditTemplateData((prev: EditTemplateProps) => ({
        ...prev,
        schedules: _.fill(prev.schedules?.slice(), scheduleTypeId, start, end),
      })),
    []
  )

  const handleDeleteSchedule = React.useCallback(
    (startAt: number, duration: number) =>
      updateEditTemplate(null, businessStartIndex + startAt, businessStartIndex + startAt + duration),
    [updateEditTemplate, businessStartIndex]
  )

  const handleSelectScheduleType = React.useCallback(
    (scheduleTypeId: number, startAt: number, duration: number) =>
      updateEditTemplate(scheduleTypeId, businessStartIndex + startAt, businessStartIndex + startAt + duration),
    [updateEditTemplate, businessStartIndex]
  )

  const handleShiftBarAdd = React.useCallback(
    (startPos: number, endPos: number) =>
      updateEditTemplate(UNSELECTED_SCHEDULE_TYPE_ID, businessStartIndex + startPos, businessStartIndex + endPos),
    [updateEditTemplate, businessStartIndex]
  )

  const handleShiftBarChange = React.useCallback(
    (index: number, x: number, width: number) => {
      const target = templateSchedules.find((_val, i) => i === index)
      if (!target) {
        return
      }
      setEditTemplateData((prev: EditTemplateProps) => ({
        id: prev.id,
        schedules: _.chain(prev.schedules?.slice())
          .fill(null, target.startIndex, target.startIndex + target.count)
          .fill(target.scheduleTypeId, businessStartIndex + x, businessStartIndex + x + width)
          .valueOf(),
        name: prev.name,
      }))
    },
    [templateSchedules, businessStartIndex]
  )

  const handleCancel = React.useCallback(() => {
    if (selectedTemplateId === NEW_TEMPLATE_ID) {
      setListItems(prev => prev.filter(item => item.id !== NEW_TEMPLATE_ID))
      const newId = !_.isEmpty(templateList?.templates) && templateList?.templates[0].id
      if (newId) {
        setSelectedTemplateId(newId)
      }
    }
    templateData &&
      setEditTemplateData({
        id: templateData.id,
        name: templateData.name,
        schedules: templateData.schedules,
      })
  }, [selectedTemplateId, templateData, templateList])

  const handleAddNewItem = React.useCallback(() => {
    setListItems(prev => prev.concat([{ title: '', id: NEW_TEMPLATE_ID }]))
    setEditTemplateData({
      ...initEditTemplateData,
      id: NEW_TEMPLATE_ID,
    })
    setSelectedTemplateId(NEW_TEMPLATE_ID)
  }, [])

  const handleListItemChange = React.useCallback(
    (id: number) => {
      if (selectedTemplateId === id) {
        return
      }
      dispatch(getTemplateList(workspaceId))
      setSelectedTemplateId(id)
    },
    [dispatch, workspaceId, selectedTemplateId]
  )

  const handleTemplateDeleteSuccess = React.useCallback(() => {
    dispatch(showSuccess())
    setOpenDelete(false)
    dispatch(getTemplateList(workspaceId))
  }, [dispatch, workspaceId])

  const shiftBarItems = React.useMemo(() => {
    return templateSchedules.map((val, index) => {
      const target = scheduleTypes.find(st => st.scheduleTypeId === val.scheduleTypeId)
      const selected = { id: val.scheduleTypeId!, name: target?.name ?? '', color: target?.color ?? '' }
      const startTime = moment()
        .startOf('day')
        .add(val.startIndex * 15, 'minutes')
        .format()
      const x = getShiftBarXbyStartTime(startTime)

      return {
        id: `item-${index}`,
        content: (
          <WorkPlanPopover
            workspaceId={workspaceId}
            scheduleId={val.scheduleTypeId!}
            selected={selected}
            viewWorkspace={false}
            startTime={startTime}
            duration={val.count * 900}
            disabled={false}
            onSelect={item => handleSelectScheduleType(item.id, x, val.count)}
            onDelete={() => handleDeleteSchedule(x, val.count)}
            isGroup={false}
          />
        ),
        x,
        width: val.count,
        color: target?.color,
        disabled: false,
      }
    })
  }, [
    getShiftBarXbyStartTime,
    scheduleTypes,
    workspaceId,
    templateSchedules,
    handleSelectScheduleType,
    handleDeleteSchedule,
  ])

  return (
    <NavMenu>
      <div className="mt-3 mx-3">
        <div className="mb-3">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex">
              <div className="font-x-large flex-grow-1 fw-bold">テンプレート管理</div>
              <div className="px-2 align-self-center">{workspace && <BadgeLabel label={badgeLabel} />}</div>
            </div>
            <div className="d-flex justify-content-end align-items-center">
              <div className="me-2 text-gray">{listItems.length} / 20 利用中</div>
              <CustomButton
                icon="plus"
                onClick={handleAddNewItem}
                className="ms-2"
                disabled={listItems.length >= 20 || listItems.some(item => item.id === NEW_TEMPLATE_ID)}
              >
                テンプレートの追加
              </CustomButton>
            </div>
          </div>
        </div>
        <Row className={styles.row}>
          <Col md={4} className="h-100">
            <Card className={styles.list}>
              {hasItems ? (
                <List
                  items={listItems}
                  selectedId={selectedTemplateId}
                  onAction={((id: number) => handleListItemChange(id)) as (selected: string | number) => void}
                />
              ) : (
                <CardBody className="d-flex align-items-center justify-content-center">
                  <div className="text-center">
                    <img className={`mx-auto d-block w-100 ${styles.placeholderImage}`} src={placeholderImage} alt="" />
                    <div className="font-middle fw-bold py-4">テンプレートがまだ登録されていません</div>
                    <div>
                      作業計画のテンプレートを作成できます。テンプレートは個人の1日の予定単位で作成が可能です。作業計画画面で複数人選択して、一括でテンプレートを入力する事も可能です。
                    </div>
                    <Button className="mx-auto d-block m-4" size="sm" outline onClick={onDetailClick}>
                      テンプレートについてもっと詳しく
                    </Button>
                  </div>
                </CardBody>
              )}
            </Card>
          </Col>
          <Col md={8} className="h-100">
            <Card className="h-100">
              {hasItems ? (
                <>
                  <div className="h-100 overflow-auto">
                    <CardBody>
                      <div className="d-flex justify-content-between">
                        <CardTitle className="font-large fw-bold">テンプレート情報</CardTitle>
                        <span className="font-x-small text-muted">※必須項目</span>
                      </div>
                      <InputFormat
                        label="名前※"
                        placeholder="テンプレート名"
                        value={editTemplateData.name}
                        validations={[templateNameValidation]}
                        maxLength={100}
                        size={ColumnSizes.middle}
                        onChange={value =>
                          setEditTemplateData({
                            id: editTemplateData.id,
                            name: value,
                            schedules: editTemplateData.schedules ?? [],
                          })
                        }
                        onValidate={setTemplateNameValidity}
                      />
                    </CardBody>

                    <CardBody>
                      <CardTitle className="font-large fw-bold">テンプレート</CardTitle>

                      <div className="text-nowrap overflow-auto border-end mt-3">
                        <TimeScale />
                        <ShiftBar
                          items={shiftBarItems}
                          businessStartTime={businessStartTime}
                          shiftBarWidth={businessDuration}
                          onAdd={(startPos, endPos) => handleShiftBarAdd(startPos, endPos)}
                          onChange={(idx, x, width) => handleShiftBarChange(idx, x, width)}
                          disabled={_.isEmpty(scheduleTypes)}
                        />
                      </div>
                    </CardBody>

                    <CardBody>
                      <CardTitle className="font-large fw-bold">テンプレートの削除</CardTitle>
                      <Button
                        outline
                        color="danger"
                        className="my-3"
                        onClick={() => setOpenDelete(true)}
                        disabled={selectedTemplateId === NEW_TEMPLATE_ID}
                      >
                        このテンプレートを削除
                      </Button>
                    </CardBody>
                  </div>
                  <CardSubmitFooter
                    onCancel={handleCancel}
                    onSubmit={handleSubmit}
                    submitDisabled={disabled || unchanged}
                    cancelDisabled={unchanged}
                    updatedBy={updated?.updatedBy}
                    updatedAt={updated?.updatedAt}
                  />
                </>
              ) : (
                <CardBody className="d-flex align-items-center justify-content-center">
                  <div className="text-center">
                    <img className={`mx-auto d-block ${styles.placeholderImage}`} src={placeholderImage} alt="" />
                    <div className="font-middle fw-bold py-4">テンプレートが選択されていません</div>
                    <div>テンプレートを選択して、詳細情報を編集しましょう。</div>
                  </div>
                </CardBody>
              )}
            </Card>
          </Col>
        </Row>

        <TemplateDelete
          isOpen={openDelete}
          workspaceId={workspaceId}
          templateId={selectedTemplateId}
          onSuccess={handleTemplateDeleteSuccess}
          onCancel={() => setOpenDelete(false)}
        />
      </div>
    </NavMenu>
  )
}

export default TemplateEdit
