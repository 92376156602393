import { createSlice } from '@reduxjs/toolkit'

import * as API from 'api/bop_reports'
import { makeErrorMessage, UNAUTHORIZED_ERROR_STATUS_CODE } from 'api/utils'

import * as NetworkErrorDialog from 'slices/networkErrorDialogSlice'
import { validateToken } from 'slices/sessionSlice'
import * as SessionTimeoutDialog from 'slices/sessionTimeoutDialogSlice'
import * as Spinner from 'slices/spinnerSlice'
import { commonParams } from 'slices/utils'

import type { PayloadAction } from '@reduxjs/toolkit'
import type { AxiosError } from 'axios'
import type { AppThunk, RootState } from 'store'

type BopReportsState = {
  isRequesting: boolean
  errorMessage: string
  bopReports: API.BopReportsProfitAndLossResponse | undefined
  bopReportsBop: API.BopReportsBopResponse | undefined
}

const initialState: BopReportsState = {
  isRequesting: false,
  errorMessage: '',
  bopReports: undefined,
  bopReportsBop: undefined,
}

export const bopReportsSlice = createSlice({
  name: 'bopReports',
  initialState,
  reducers: {
    startRequest: state => {
      state.isRequesting = true
      state.errorMessage = ''
    },
    apiFailure: (state, action: PayloadAction<{ errorMessage: string }>) => {
      state.isRequesting = false
      state.errorMessage = action.payload.errorMessage
    },
    getBopReportsSuccess: (state, action: PayloadAction<API.BopReportsBopResponse>) => {
      state.isRequesting = false
      state.bopReportsBop = action.payload
    },
    getBopReportsProfitAndLossSuccess: (state, action: PayloadAction<API.BopReportsProfitAndLossResponse>) => {
      state.isRequesting = false
      state.bopReports = action.payload
    },
  },
})

export const { startRequest, apiFailure, getBopReportsSuccess, getBopReportsProfitAndLossSuccess } =
  bopReportsSlice.actions

export const getBopReports =
  (data: API.BopMonitoringReportParam): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    API.getBopReportsBop(commonParams(getState), data)
      .then((res: API.BopReportsBopResponse) => dispatch(getBopReportsSuccess(res)))
      .catch((res: AxiosError) => {
        const errorCode = makeErrorMessage(res)
        if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
          dispatch(SessionTimeoutDialog.open())
        } else {
          dispatch(NetworkErrorDialog.open({ code: errorCode }))
        }
        dispatch(apiFailure({ errorMessage: errorCode }))
      })
      .finally(() => dispatch(Spinner.stop()))
  }

export const getBopReportsProfitAndLoss =
  (from: string, to: string): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    API.getBopReportsProfitAndLoss(commonParams(getState), from, to)
      .then((res: API.BopReportsProfitAndLossResponse) => dispatch(getBopReportsProfitAndLossSuccess(res)))
      .catch((res: AxiosError) => {
        const errorCode = makeErrorMessage(res)
        if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
          dispatch(SessionTimeoutDialog.open())
        } else {
          dispatch(NetworkErrorDialog.open({ code: errorCode }))
        }
        dispatch(apiFailure({ errorMessage: errorCode }))
      })
      .finally(() => dispatch(Spinner.stop()))
  }

export const selectBopReportsStatus = (state: RootState) => ({ ...state.bopReports })

export default bopReportsSlice.reducer
